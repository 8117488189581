import { useEffect } from 'react';
import cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { identify } from '@cbhq/client-analytics';

// Cookie name for the device id set by the monorail
const COOKIE_DEVICE_ID = 'coinbase_device_id';

// https://www.wolframalpha.com/input/?i=630720000000+milliseconds
const TWENTY_YEARS_AS_MS = 630_720_000_000;

function getDeviceId() {
  let deviceId = cookie?.get(COOKIE_DEVICE_ID);
  if (!deviceId) {
    deviceId = uuidv4();
    cookie.set(COOKIE_DEVICE_ID, deviceId, { expires: new Date(Date.now() + TWENTY_YEARS_AS_MS) });
  }
  return deviceId;
}

export function useIdentifyUser({ userId }: { userId?: string }) {
  useEffect(
    function identifyUser() {
      identify({
        userId,
        deviceId: getDeviceId(),
      });
    },
    [userId],
  );
}

export function identifyDevice() {
  // userId and deviceId are required for amplitude
  identify({
    deviceId: getDeviceId(),
  });
}
