/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import { ReactElement } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { Accordion, AccordionItem } from '@cbhq/cds-web/accordion';
import { VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextDisplay3 } from '@cbhq/cds-web/typography';

const i18nKey = 'FAQ';
const messages = defineMessages({
  faqTitle: {
    id: `${i18nKey}.title`,
    description: 'Faq Title ',
    defaultMessage: 'Frequently asked questions',
  },
  participateInQuestTitle: {
    id: `${i18nKey}.participateInQuestTitle`,
    description: 'participate in quest title',
    defaultMessage: 'Why should I participate in the Quest?',
  },
  participateInQuestBody: {
    id: `${i18nKey}.participateInQuestBody`,
    description: 'participate in quest body',
    defaultMessage:
      'Our vision with Base is to bring the next billion people on-chain, and we can only accomplish this with builders like you. Become one of the early builders to deploy your smart contract on Base, and earn a commemorative NFT.',
  },
  whatWalletsTitle: {
    id: `${i18nKey}.whatWalletsTitle`,
    description: 'what wallets title',
    defaultMessage: 'What wallets can I use for the Quest?',
  },
  whatWalletsBody: {
    id: `${i18nKey}.whatWalletsBody`,
    description: 'what wallets body',
    defaultMessage:
      'MetaMask, Wallet Connect, Rainbow, Coinbase Wallet, Ledger, and any injected wallet.',
  },
  whyCoinbaseWalletTitle: {
    id: `${i18nKey}.whyCoinbaseWalletTitle`,
    description: 'why coinbase wallet title',
    defaultMessage: 'Why is Coinbase Wallet recommended?',
  },
  whyCoinbaseWalletBody: {
    id: `${i18nKey}.whyCoinbaseWalletBody`,
    description: 'why coinbase wallet body',
    defaultMessage:
      'Coinbase Wallet offers users an easy way to access testnet ETH. Participants can simply connect to the Base faucet from within Coinbase Wallet and get the testnet funds required for the Builder Quest. \nUsers who prefer to use a different wallet can connect to an alternative faucet. Check out our {faucetDocs}.',
  },
  transferrableTitle: {
    id: `${i18nKey}.transferrableTitle`,
    description: 'transferrable title',
    defaultMessage: 'Is the testnet NFT transferable?',
  },
  transferrableBody: {
    id: `${i18nKey}.transferrableBody`,
    description: 'transferrable body',
    defaultMessage: 'No, the testnet NFT is non-transferable.',
  },
  freeNFTTitle: {
    id: `${i18nKey}.freeNFTTitle`,
    description: 'free nft question',
    defaultMessage: 'Is the NFT free?',
  },
  freeNFTBody: {
    id: `${i18nKey}.freeNFTBody`,
    description: 'free nft body',
    defaultMessage:
      'The NFT is free aside from the gas fees (which will be paid by the person minting the NFT).',
  },
  mainnetNFTitle: {
    id: `${i18nKey}.mainnetNFTTitle`,
    description: 'how to claim mainnet nft',
    defaultMessage: 'How can I claim my NFT on mainnet?',
  },
  mainnetNFTBody: {
    id: `${i18nKey}.mainnetNFTBody`,
    description: 'how to claim mainnet nft body',
    defaultMessage:
      'We will share more information on this closer to date. Only builders who have completed the quest and minted the testnet NFT will be eligible for the mainnet NFT.',
  },
  stayInTouchTitle: {
    id: `${i18nKey}.stayInTouchTitleTitle`,
    description: 'stay in touch title',
    defaultMessage: 'How can I stay in touch with the Base community?',
  },
  stayInTouchBody: {
    id: `${i18nKey}.mainnetNFTBody`,
    description: 'stay in touch body',
    defaultMessage:
      'Follow us on {twitter} to stay up-to-date with the latest about Base, and come chat with us and other Base builders on {discord}. You can also check out our {mirrorBlog} for more about our origins and approach.',
  },
});

export type FAQItem = {
  id: string;
  heading: MessageDescriptor;
  body?: MessageDescriptor;
  bodyElement?: ReactElement;
};

const faqs: FAQItem[] = [
  {
    id: '1',
    heading: messages.participateInQuestTitle,
    body: messages.participateInQuestBody,
  },
  {
    id: '2',
    heading: messages.whatWalletsTitle,
    body: messages.whatWalletsBody,
  },
  {
    id: '3',
    heading: messages.whyCoinbaseWalletTitle,
    body: messages.whyCoinbaseWalletBody,
    bodyElement: (
      <FormattedMessage
        {...messages.whyCoinbaseWalletBody}
        values={{
          faucetDocs: <Link to="https://docs.base.org/tools/network-faucets/">faucet docs</Link>,
        }}
      />
    ),
  },
  {
    id: '4',
    heading: messages.transferrableTitle,
    body: messages.transferrableBody,
  },
  {
    id: '5',
    heading: messages.freeNFTTitle,
    body: messages.freeNFTBody,
  },
  {
    id: '6',
    heading: messages.mainnetNFTitle,
    body: messages.mainnetNFTBody,
  },
  {
    id: '7',
    heading: messages.stayInTouchTitle,
    body: messages.stayInTouchBody,
    bodyElement: (
      <FormattedMessage
        {...messages.stayInTouchBody}
        values={{
          twitter: <Link to="https://twitter.com/BuildOnBase">Twitter</Link>,
          discord: <Link to="https://base.org/discord"> Discord </Link>,
          mirrorBlog: <Link to="https://base.mirror.xyz/">Mirror Blog</Link>,
        }}
      />
    ),
  },
];

export function Faq() {
  const { formatMessage } = useIntl();
  return (
    <VStack maxWidth={1200} height="100%">
      <TextDisplay3 as="h3" mono transform="uppercase">
        {formatMessage(messages.faqTitle)}
      </TextDisplay3>
      <Accordion>
        {faqs.map((faqItem, idx) => (
          <AccordionItem
            title={formatMessage(faqItem.heading)}
            itemKey={faqItem?.id ?? idx}
            key={faqItem?.id ?? idx}
          >
            <TextBody as="p">
              {faqItem.bodyElement ? faqItem.bodyElement : <FormattedMessage {...faqItem.body} />}
            </TextBody>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
}
