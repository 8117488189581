import { ReactElement } from 'react';
import { Box } from '@cbhq/cds-web/layout';
import { TextLabel2, TextTitle1, TextTitle3 } from '@cbhq/cds-web/typography';

type StepCardProps = {
  step: number;
  title: string;
  description: string;
  descriptionElement?: ReactElement;
};

export function StepCard({ step, title, description, descriptionElement }: StepCardProps) {
  return (
    <Box
      flexDirection="column"
      width="100%"
      minHeight="376px"
      dangerouslySetBackground="#1E2025"
      spacing={4}
    >
      <TextTitle1 as="h3" mono tabularNumbers>
        {step}
      </TextTitle1>
      <Box display="block" spacingTop={8}>
        <TextTitle3 as="h4" transform="uppercase" mono>
          {title}
        </TextTitle3>

        <TextLabel2 as="p" spacingTop={1}>
          {descriptionElement ?? description}
        </TextLabel2>
      </Box>
    </Box>
  );
}
