import { ReactElement, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StepCard } from 'apps/quests/src/components/StepCard/StepCard';
import { ResponsiveGridStyles, ResponsivePropsDevices } from '@cbhq/cds-web';
import { Grid, GridColumn, VStack } from '@cbhq/cds-web/layout';
import { Link, TextDisplay3 } from '@cbhq/cds-web/typography';

type Instruction = {
  step: number;
  title: string;
  description: string;
  descriptionElement?: ReactElement;
};

const i18nKey = 'Instructions';

const messages = defineMessages({
  instructions: {
    id: `${i18nKey}.instructions`,
    defaultMessage: 'Instructions',
    description: 'instructions title',
  },
  step1Title: {
    id: `${i18nKey}.step1Title`,
    defaultMessage: 'GET TESTNET FUNDS',
    description: 'instruction step 1 title',
  },
  step1Description: {
    id: `${i18nKey}.step1Description`,
    defaultMessage:
      'Make sure your wallet has Base testnet ETH in order to complete the Quest. You can get testnet ETH from the',
    description: 'instruction step 1 description',
  },
  step2Title: {
    id: `${i18nKey}.step2Title`,
    defaultMessage: 'CONNECT WALLET',
    description: 'instruction step 2 title',
  },
  step2Description: {
    id: `${i18nKey}.step2Description`,
    defaultMessage: 'Connect your self-custodial wallet and sign the Quest message.',
    description: 'instruction step 2 description',
  },
  step3Title: {
    id: `${i18nKey}.step3Title`,
    defaultMessage: 'DEPLOY SMART CONTRACT',
    description: 'instruction step 3 title',
  },
  step3Description: {
    id: `${i18nKey}.step3Description`,
    defaultMessage:
      'Deploy your smart contract on the Base testnet. Use your testnet ETH for the network fee.',
    description: 'instruction step 3 description',
  },
  step4Title: {
    id: `${i18nKey}.step4Title`,
    defaultMessage: 'MINT YOUR NFT',
    description: 'instruction step 4 title',
  },
  step4Description: {
    id: `${i18nKey}.step4Description`,
    defaultMessage:
      'After verification, you will be prompted to a success page to mint your NFT! You can mint a second NFT after Base transitions to mainnet.',
    description: 'instruction step 4 description',
  },
});

export function QuestInstructions() {
  const { formatMessage } = useIntl();
  const steps = useMemo<Instruction[]>(() => {
    return [
      {
        step: 1,
        title: formatMessage(messages.step1Title),
        description: formatMessage(messages.step1Description),
        descriptionElement: (
          <>
            <FormattedMessage {...messages.step1Description} />{' '}
            <Link to="https://coinbase.com/faucets/base-ethereum-goerli-faucet">Base faucet</Link>.
          </>
        ),
      },
      {
        step: 2,
        title: formatMessage(messages.step2Title),
        description: formatMessage(messages.step2Description),
      },
      {
        step: 3,
        title: formatMessage(messages.step3Title),
        description: formatMessage(messages.step3Description),
      },
      {
        step: 4,
        title: formatMessage(messages.step4Title),
        description: formatMessage(messages.step4Description),
      },
    ];
  }, [formatMessage]);

  const responsiveConfig: Partial<Record<ResponsivePropsDevices, ResponsiveGridStyles>> = useMemo(
    () => ({
      phone: {
        columns: 1,
      },
      tablet: {
        columns: 3,
      },
      desktop: {
        columns: 4,
      },
    }),
    [],
  );

  return (
    <VStack maxWidth={1200} height="100%">
      <TextDisplay3 as="h3" mono transform="uppercase">
        {formatMessage(messages.instructions)}
      </TextDisplay3>
      <Grid responsiveConfig={responsiveConfig} spacingTop={4} gap={2} as="ul" spacingStart={0}>
        {steps.map(({ step, title, description, descriptionElement }) => (
          <GridColumn key={title} colSpan={1} as="li">
            <StepCard
              step={step}
              title={title}
              description={description}
              descriptionElement={descriptionElement}
            />
          </GridColumn>
        ))}
      </Grid>
    </VStack>
  );
}
