import { useCallback, useMemo } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import { BaseQuestStartProps } from 'apps/quests/src/components/BaseQuest/BaseQuestStart';
import { Faq } from 'apps/quests/src/components/Faq/Faq';
import { QuestInstructions } from 'apps/quests/src/components/QuestInstructions/QuestInstructions';
import { useIdentifyUser } from 'apps/quests/src/init/analytics/identify';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Box, VStack } from '@cbhq/cds-web/layout';

const BaseQuestStart = dynamic<BaseQuestStartProps>(
  async () => import('../src/components/BaseQuest/BaseQuestStart'),
  {
    ssr: false,
  },
);

export default function HomePage() {
  const router = useRouter();
  const address = useAddress();

  const handleStarted = useCallback(() => {
    router.push('/quest').catch(() => {});
  }, [router]);

  const backgroundImageStyle = useMemo(
    () => ({
      background: "url('images/background.png')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'background',
    }),
    [],
  );

  useIdentifyUser({ userId: address });

  return (
    <>
      <Box height="100vh" width="100%">
        <VStack dangerouslySetStyle={backgroundImageStyle} height="100%" width="100%">
          <VStack
            height="100%"
            spacingVertical={10}
            spacingHorizontal={8}
            alignItems="flex-start"
            justifyContent="flex-end"
            maxWidth={1200}
          >
            <BaseQuestStart onStart={handleStarted} />
          </VStack>
        </VStack>
      </Box>
      <VStack
        width="100%"
        height="100%"
        flexGrow={1}
        spacingVertical={10}
        alignItems="center"
        justifyContent="center"
        background="background"
      >
        <Box spacingHorizontal={8} justifyContent="center" flexDirection="row">
          <QuestInstructions />
        </Box>
      </VStack>
      <VStack
        width="100%"
        height="100%"
        flexGrow={1}
        spacingBottom={10}
        alignItems="center"
        justifyContent="center"
        background="background"
      >
        <Box spacingHorizontal={8} justifyContent="center" flexDirection="row">
          <Faq />
        </Box>
      </VStack>
    </>
  );
}
